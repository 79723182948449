html, body {
    background-color: white;
}

header {
    display: block;
    max-width: 400px;
    margin: 0 auto;
}

footer {
    display: block;
    max-width: 512px;
    margin: 0 auto;
}

.App {
    max-width: 650px;
    margin: 64px auto 0 auto;

    color: black;
    font-family: sans-serif;
    font-size: 14px;
    text-align: center;
}

.App-counter {
    margin: 32px 0;
    font-size: 64px;
    font-weight: bold;
}

.App-pastyear {
    display: inline;
}

.App-pastyear ~ .App-pastyear::before {
    content: ', ';
}

/* Heh, yeah, I'm a professional frontend dev. Why do you ask? */
.App-lilskip {
    margin-top: 24px;
}

.App-footer {
    margin-top: 32px;
    font-size: 10px;
}

.App-bee {
    display: inline-block;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
